<template>
    <div class="title">系統數據</div>
    <el-row>
        <el-col :span="8">
            <el-statistic value="22/09/2024" title="官網維護到期時間" ></el-statistic>
        </el-col>
        <el-col :span="8">
            <el-statistic value="N/A" title="本系統維護到期時間" ></el-statistic>
        </el-col>
        <el-col :span="8">
            <el-statistic value="05/02/2025" title="服務器到期時間" ></el-statistic>
        </el-col>
    </el-row>
</template>
<script setup>

</script>
